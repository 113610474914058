import _ from 'lodash'

import classNames from 'classnames'
import React  from 'react'
import html2canvas from 'html2canvas';
import { LabelFormGroup } from 'browser/components/atomic-elements/molecules/label-form-group/label-form-group'
import { v4 as uuidv4 } from 'uuid'

import moment from 'moment'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { CheckboxField } from 'browser/components/atomic-elements/molecules/fields/checkbox-field'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
import { SignatureField } from 'browser/components/atomic-elements/molecules/fields/signature-field/signature-field'
import { FramesManager } from 'shared-libs/components/view/frames-manager';
import 'browser/components/atomic-elements/molecules/fields/esignature-field/_esignature-field.scss'

/**
 * A collection of fields to form a esignature field
 * 1. A checkbox to show intent that user wants to sign
 * 2. Generated signature (cursive) of the user name
 * 3. Date of signature
 *
 * Once the user clicks on "Save", it will screenshot via html2canvas the generated signature and
 * send to the server as jpg
 *
 * @uiComponent
 */
export interface ISignatureFieldProps extends IBaseProps {
  value: Record<string, any>
  isHorizontalLayout?: boolean
  label?: string
  previewLabel?: string
  timestampLabel?: string
  nameLabel?: string
  signatureDisclosure?: string
  entity?:  any
  immutableSignature?: boolean // once signed, it is readonly

  inputWidthClassName?: string
  isDisabled?: boolean
  frames?: FramesManager
  onChange: (value) => void

  showSignatureCheckbox: boolean
}

interface  IESignatureFieldState {

}

/**
 * A collection of fields to form a esignature field
 * 1. A checkbox to show intent that user wants to sign
 * 2. Generated signature (cursive) of the user name
 * 3. Date of signature
 *
 * Once the user clicks on "Save", it will screenshot the generated signature and
 * send to the server as jpg
 */
export class ESignatureField
  extends React.Component<ISignatureFieldProps, IESignatureFieldState> {

  public static defaultProps: Partial<ISignatureFieldProps> = {
    immutableSignature: true,
    isHorizontalLayout: true,
    inputWidthClassName: 'c-formGroup-horizontalContent',

    label: 'Signature',
    nameLabel: 'Signer Name',
    previewLabel: 'Signature Preview',
    timestampLabel: 'Signature Date',

    signatureDisclosure: 'By checking this box, I agree to use electronic records and signatures and that the below Signature Preview will be the electronic representation of my signature whenever I use it. Please refer to the Electronic Record and Signature Disclosure section in Vector’s Terms of Service found under Settings.',
    showSignatureCheckbox: true,
}

  private generatedSignatureId: any
  private generatedSignatureComponentId: string
  private readOnly: boolean

  constructor(props: ISignatureFieldProps) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.updateGeneratedSignature = this.updateGeneratedSignature.bind(this)
    this.generatedSignatureId = uuidv4()
    this.generatedSignatureComponentId = this.getValuePath()

    const { isDisabled, immutableSignature, value } = props
    const isSigned = _.get(value, 'signed', false)
    this.readOnly = isDisabled || (isSigned && immutableSignature)
  }

  public render() {
   return (
      <>
        {this.renderSignerName()}
        {this.renderSignatureCheckbox()}
        {this.renderSignature()}
        {this.renderSignatureDate()}
      </>
    )
  }

  private renderSignatureCheckbox = () => {
    const { value, label, isHorizontalLayout,
   signatureDisclosure, showSignatureCheckbox } = this.props

    if (!showSignatureCheckbox) {
      return null
    }

    const signatureType  = _.get(value, 'signatureType',  'checkbox')
    if (signatureType === 'pad') {
      return null
    }


    const signedValue = _.get(value, 'signed', false)
    const signatureCheckboxProps = {
      ...this.props as any,
      isHorizontalLayout,
      horizontalLabel: label,
      value: signedValue,
      label: signatureDisclosure,
      isDisabled: this.readOnly,
      onChange: this.handleChange
    }

    return (
      <CheckboxField {...signatureCheckboxProps} />
    )
  }

  private renderSignerName = () => {
    const { value, nameLabel } = this.props
    const signer = _.get(value, 'signer' )

    return (
      <InputField
        isHorizontalLayout={true}
        label={nameLabel}
        value={signer}
        format='date-time'
        isDisabled={true}
      />
    )
  }

  private renderSignatureDate = () => {
   const { value, timestampLabel } = this.props
    const signedDate = _.get(value, 'signedDate', '')
    return (
      <InputField
        isHorizontalLayout={true}
        label={timestampLabel}
        value={signedDate}
        format='date-time'
        type='string'
        isDisabled={true}
      />
    )
  }

  private renderSignature = () => {
    const { value, previewLabel, showSignatureCheckbox } = this.props
    const signatureFile = _.get(value, 'signature', {})
    const signatureUri = _.get(signatureFile, 'uri', '')

    if (this.isSigned() && _.isEmpty(signatureUri)) {
      return this.renderGeneratedSignature();
    }

    return (
      <SignatureField
        isHorizontalLayout={true}
        label={previewLabel}
        value={signatureFile}
        emptySignaturePlaceholder={ showSignatureCheckbox && 'Check the box above to indicate intent and consent, eSignature will be rendered afterward' || 'No Signature'}
      />
    )
  }

  private renderGeneratedSignature = () => {
    const { previewLabel, isHorizontalLayout, className } = this.props
    const name = this.signerDigitalSignature()
    return (
      <LabelFormGroup
      label={previewLabel}
      isHorizontalLayout={isHorizontalLayout}
      value={this.signerId()}
    >
      <div
        id={this.generatedSignatureComponentId}
        className={classNames('u-bumperLeft--xl u-bumperTop u-bumperBottom', className)}>
        <div className={classNames('u-bumperBottom', className)}>
          Vector eSigned by:
        </div>
        <div className={classNames('u-bumperBottom c-signature-preview', className)}>
          {name}
        </div>
        <div>
          {this.signerId()}
        </div>
      </div>
    </LabelFormGroup>
    )
  }

  private signerName = () => {
    const { entity } = this.props
    return entity.api.settings.getUser().displayName
  }

  private signerId = () => {
    const { entity } = this.props
    return entity.api.settings.getUser().uniqueId
  }

  private signerFirmId = () => {
    const { entity } = this.props
    return entity.api.settings.getFirm().uniqueId
  }

  private signerDigitalSignature = () => {
    return _.replace(this.signerName(), ' ', '')
  }

  private handleChange = (isSigned) => {
    const { onChange, entity } = this.props
    let updatedValue = {}

    if (isSigned) {
      const name = this.signerName()
      const signer = this.signerDigitalSignature()
      updatedValue =
        {
          signatureType: 'checkbox',
            signed: isSigned,
            signer: name,
            signedBy: {
              user: {
                entityId:  this.signerId()
              },
              firm: {
                entityId: this.signerFirmId()
              }
            },
            signature: {
              name: `${signer}.png`,
              type: 'image',
              uniqueId: this.generatedSignatureId
            },
            signedDate: moment().toISOString()
        }

      entity.addPreSaveAction({
        name: `screenShotGeneratedSignature-${this.getValuePath()}`,
        action: this.updateGeneratedSignature
      })
    }
    onChange(updatedValue)
  }

  private isSigned = () => {
    const { value } = this.props
    return _.get(value, 'signed', false)
  }

  private screenshotGeneratedSignature = () => {
    const previewId = this.generatedSignatureComponentId
    const previewComponent = document.getElementById(previewId)

    if (_.isNil(previewComponent)) {
      return Promise.resolve(null)
    }

    return new Promise((resolve) => {
      html2canvas(previewComponent).then((canvas) => {
        canvas.toBlob((blob) => {
          resolve(blob)
        })
      })
    })
  }

  // set the generated png file back to the entity for upload
  private updateGeneratedSignature = (entity) => {
    const valuePath = this.getValuePath()
    if (this.isSigned()) {
      return this.screenshotGeneratedSignature().then((blob: any) => {
        if (_.isNil(blob)) {
          return Promise.resolve()
        }
        const signer = this.signerDigitalSignature()
        const file = new File([blob], `${signer}.png`, {type: 'image/png'})

        const signaturePath = `${valuePath}.signature`
        entity.clearMultipartFiles(signaturePath)
        entity.addMultipartFiles(signaturePath, [{file, uniqueId: this.generatedSignatureId}])
        return Promise.resolve()
      })
    }
    return Promise.resolve()
  }

  private getValuePath = () => {
    const { frames } = this.props
    const valuePath = frames.getContext('valuePath')
    return valuePath.join('.')
  }
}
